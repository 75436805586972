document.addEventListener('DOMContentLoaded', () => {
  enableMenu()
  enableBannersSlider()
  enableProductsSlider()
})


/* FUNCTIONS */

const enableMenu = () => {
  const nav = document.querySelector('.Page-Nav')
  const openNav = document.querySelector('.Btn--menu')
  const cloak = document.querySelector('.Page-Cloak')
  const pageBody = document.querySelector('.Page-Body')
  
  const closeNav = () => {
    pageBody.removeAttribute('style')
    nav.classList.remove('Nav--open')
    cloak.classList.remove('Page-Cloak--on')
  }
  
  
  // close Nav and Cloak
  cloak.addEventListener('click', evt => {
    if (evt.target === cloak) {
      closeNav()
    }
  })
  
  
  // show Navigation and Cloak main
  openNav.addEventListener('click', () => {
    pageBody.style.height = '100vh'
    pageBody.style.overflow = 'hidden'
    nav.classList.add('Nav--open')
    cloak.classList.add('Page-Cloak--on')
  })
  
  // Manage clicks on Navigation
  nav.addEventListener('click', (evt) => {
    
    // close Nav on CloseBtn
    if (evt.target.closest('.Nav-Btn.Btn--close')) {
      return closeNav()
    }
    
    
    // expand/collapse SubMenu
    const itemSubMenus = nav.querySelectorAll('.Nav-Item--submenu')
    const isLink = evt.target.matches('.Nav-Link')
    const isSubLink = evt.target.matches('.Nav-Link--sublink')
    const isSubMenu = evt.target.closest('.Nav-Item')?.classList.contains('Nav-Item--submenu')
    
    // close Nav if click on item without subMenu
    if (isLink && !isSubMenu) {
      return closeNav()
    }
    
    itemSubMenus.forEach(el => {
      if (window.innerWidth < 1350) {
        if (el === evt.target.closest('.Nav-Item--submenu') && !isSubLink)  {
          el.classList.toggle('Nav-Item--open')
          el.querySelector('.Nav-List--sublist').classList.toggle('Nav-List--open')
        }
        else {
          el.classList.remove('Nav-Item--open')
          el.querySelector('.Nav-List--sublist').classList.remove('Nav-List--open')
        }
      }
    })
    
  })
}

const enableBannersSlider = () => {

  const bannersSlider = document.querySelector('.Banners-Slider')
  
  // делаем ничего если слайдера на странице нет
  if(!bannersSlider) { return }
  
  // собираем элементы
  const sliderBox = bannersSlider?.querySelector('.Banners-SlidersBox')
  const sliderItems = bannersSlider?.querySelectorAll('.Banners-SliderItem')
  const sliderControl = bannersSlider?.querySelector('.Banners-SliderControls')
  
  let sliderWidth = bannersSlider.getBoundingClientRect().width
  let dotsHTML = ''
  
  // генерируем кнопки выбора слайда
  sliderItems.forEach((el, i) => {
    el.style.width = sliderWidth + 'px'
    dotsHTML = dotsHTML + `<button class="Banner-SliderBtn Btn Btn--dot ${i === 0 ? 'active' : ''}" data-slidenum="${i}"></button>`
  })
  sliderControl.innerHTML = dotsHTML
  
  // находим все кнопки выбора слайда
  const dots = sliderControl.querySelectorAll('.Banner-SliderBtn')
 
  // при клике двигаем блок слайдов на нужное расстояние и помечаем кнопку активной
  sliderControl.addEventListener('click', (evt) => {
    evt.preventDefault()
    evt.stopPropagation()
    
    if (evt.target.tagName === 'BUTTON') {
      let num = evt.target.dataset.slidenum
      sliderBox.style.left = `-${+num * sliderWidth}px`
      dots.forEach((el) => {
        if (el === evt.target) {
          el.classList.add('active')
        }
        else {
          el.classList.remove('active')
        }
      })
    }
  })
 
  
  // исправляем размер SliderItem и позицию SlidersBox при изменения размеров окна браузера
  let resizeTimeout = 0
  
  const resizeThrottler = () => {
    // ignore resize events as long as an actualResizeHandler execution is in the queue
    if (!resizeTimeout) {
      resizeTimeout = setTimeout(function () {
        resizeTimeout = null;
        actualResizeHandler();
        
        // The actualResizeHandler will execute at a rate of 15fps
      }, 66);
    }
  }
  
  const actualResizeHandler = () => {
    sliderWidth = bannersSlider.getBoundingClientRect().width
    sliderItems.forEach((el) => { el.style.width = sliderWidth + 'px' })
    
    // update SlidersBox position
    let activeSlide = +sliderControl.querySelector('.Banner-SliderBtn.active').dataset.slidenum
    sliderBox.style.transform = `translateX(-${activeSlide * sliderWidth}px)`
  }
  
  // отслеживаем изменение размера окна браузера
  window.addEventListener("resize", resizeThrottler, false)
  
  window.addEventListener('orientationchange', resizeThrottler, false)
  
  
  // добавляем возможность свайпить слайды
  
  // отключаем drag & drop чтоб не мешал свайпить
  bannersSlider.ondragstart = () => { return false; }
  
  let touchBegin = {},
    sliderBoxLeft = {}
  
  bannersSlider.addEventListener('touchstart', (event) => {
    // запоминаем позицию начала касания
    touchBegin = {X: event.touches[0].clientX, Y: event.touches[0].clientY}
    // и текущее смещение контейнера
    sliderBoxLeft = parseInt(getComputedStyle(sliderBox, null).left)
  })
  
  bannersSlider.addEventListener('touchmove', (event) => {
    // отключаем анимацию чтоб не дёргалось при перетаскивании
    sliderBox.style.transition = 'none';
    
    let shift = {X: event.touches[0].clientX - touchBegin.X,  Y: event.touches[0].clientY - touchBegin.Y}
    // touchBegin.X = event.touches[0].clientX
    
    sliderBox.style.left = `${sliderBoxLeft + shift.X}px`
    
    bannersSlider.addEventListener('touchend', endTouch)
    bannersSlider.addEventListener('touchcancel', endTouch)
    
    function endTouch(event) {
      event.preventDefault()
      
      // включаем анимацию обратно
      sliderBox.style.transition = '.5s';
      
      // если сместили слайд больше чем на 20%, то перематываем на следующий
      if(Math.abs(shift.X) >= sliderWidth * 0.2) {
        let newLeft = sliderBoxLeft + Math.sign(shift.X) * sliderWidth
        
        // запрещаем перемотку на пустоту
        if (newLeft > 0) { newLeft = 0 }
        if (newLeft < -1 * sliderWidth * (sliderItems.length - 1)) { newLeft = -1 * sliderWidth * (sliderItems.length -1) }
        
        sliderBox.style.left = `${newLeft}px`
        
        // определяем кнопку которая теперь должна быть активна
        let newNum = Math.abs(newLeft / sliderWidth)
        
        // "активируем" в соответствии с выбранным слайдом
        dots.forEach((el, i) => {
          if (newNum === i) {
            el.classList.add('active')
          }
          else {
            el.classList.remove('active')
          }
        })
      }
      else {
        // иначе возвращаем слайд обратно без перемотки
        sliderBox.style.left = sliderBoxLeft
      }
      
      bannersSlider.removeEventListener('touchend', endTouch)
      bannersSlider.removeEventListener('touchcancel', endTouch)
    }
  })
  
}

const enableProductsSlider = () => {
  
  // количество элементов на странице при ширине экрана
  const quantity = {
    0: 2,
    480: 3,
    900: 4,
    1200: 5,
    1350: 4,
    1530: 5,
  }
  
  // создаём и добавляем в body новый блок стилей
  let newStyles = document.createElement('STYLE')
  document.body.append(newStyles)
  
  // находим все слайдеры на странице и раздаём им функционал
  const productSliders = document.querySelectorAll('.Product-Slider')
  
  productSliders.forEach((pS) => {
    
    // находим элементы текущего слайдера
    const
      productsBox = pS.querySelector('.Product-List'),
      productsWrap = pS.querySelector('.Product-SliderWrap'),
      productItems = productsBox.querySelectorAll('.Product'),
      controls = pS.querySelector('.Product-SliderControls'),
      prevBtn = pS.querySelector('.Product-SliderPrev'),
      nextBtn = pS.querySelector('.Product-SliderNext')
    
    let
      itemsPerPage = 0,
      left = parseInt(getComputedStyle(productsBox, null).left),
      columnGap = parseInt(getComputedStyle(productsBox, null).columnGap),
      step = columnGap + productItems[0].offsetWidth,
      newItemWidth = null
    
    // задаём размеры карточек и включим кнопки если есть куда мотать
    updateItemsSize()
    
    // обрабатываем события кнопок
    controls.addEventListener('click', event => {
      
      if (event.target.closest('button') === nextBtn) {
        left = left - step
      }
      if (event.target.closest('button') === prevBtn) {
        left = left + step
      }
      
      correctBoxOffset()
    })
    
    
    // обновляем размер карточек при изменении ориентации устройства
    window.addEventListener('orientationchange', () => {
      updateItemsSize()
      correctBoxOffset()
    })
    
    // обновляем размер карточек при изменении размеров браузера
    window.addEventListener('resize', () => {
      updateItemsSize()
      correctBoxOffset()
    })
    
    
    function correctBoxOffset() {
      // запрещаем пустоту справа
      if (Math.abs(left) + productsWrap.clientWidth > productsBox.scrollWidth) {
        left = productsWrap.clientWidth - columnGap - productsBox.scrollWidth
      }
      
      // запрещаем пустоту слева
      if (left > 0) {
        left = 0
      }
      
      productsBox.style.left = left + 'px'
      
      updateScrollBtn()
    }
    
    function updateItemsSize() {
      // выбираем количество элементов на странице
      for (let key in quantity) {
        if (quantity.hasOwnProperty(key) && window.innerWidth >= key) {
          itemsPerPage = quantity[key]
        }
      }
      
      newItemWidth = Math.floor((productsWrap.clientWidth - (columnGap * itemsPerPage - 1)) / itemsPerPage)
      if (newItemWidth > 295) { newItemWidth = 295 }
      step = columnGap + newItemWidth
      
      updateStyles()
      
      left = left - left % step
      updateScrollBtn()
    }
    
    function updateStyles () {
      newStyles.innerHTML = `
        /* styles from enableProductsSlider() */
        .Product {
          width: ${newItemWidth}px;
        }
      `
    }
    
    function updateScrollBtn () {
      if (left >= 0) {
        prevBtn.setAttribute('disabled', '')
      }
      else {
        prevBtn.removeAttribute('disabled')
      }
      
      if (Math.abs(left) + productsWrap.offsetWidth >= productsBox.scrollWidth) {
        nextBtn.setAttribute('disabled', '')
      }
      else {
        nextBtn.removeAttribute('disabled')
      }
    }
  })
  
  
  
}
